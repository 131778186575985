<template>
  <div class="container">
    <PublicHeader :totalName="$t('about').title" />
    <!-- <img src="@/assets/img/about_bg.jpg" width="100%" height="202" /> -->
    <video :src="VideoUrl" controls autoplay muted playsinline webkit-playsinline width="100%" height="210"> </video>
    <div class="content">
      <img src="@/assets/img/logo.png" width="70" />
      <div class="info" v-html="Info.Content"></div>
    </div>

  </div>
</template>

<script>
import systemApi from '@/api/system'
import { mapGetters } from 'vuex'
import { getRandomString } from '@/utils/validate'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      Info: {},
      VideoUrl: '',
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getVideoUrl()
    this.getAboutUs()
  },
  methods: {
    // 视频地址
    async getVideoUrl() {
      const res = await systemApi.getClientConfig()
      this.VideoUrl = res.Data.VideoUrl
    },
    //获取关于我们
    async getAboutUs() {
      const res = await systemApi.getContentTitles('AboutUs', {})
      if (res.Data.length > 0) {
        var _res = await systemApi.getContentDetail({ contentId: res.Data[0].Id })
        this.Info = _res.Data
      }
    },

  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 60px 13px 0 13px;
  background: #F6F6F6;

  .content {
    position: relative;
    margin-top: 10px;
    background: #fff;
    border-radius: 5px;
    padding: 20px 13px;
    text-align: center;

    .info {
      margin-top: 15px;
      font-size: 15px;
      color: #333333;
      line-height: 23px;
      text-align: left;
    }
  }
}
</style>
